import { Option } from "../../ui/interfaces/Option"
import { convertEnumToOptionsWithCleanLabel } from "../utils"

const ArtistEnum = {
  PerformerBand: 'PerformerBand',
  SessionArtistInstrumentals: 'SessionArtistInstrumentals',
  RecordProducerStudioEngineer: 'RecordProducerStudioEngineer',
  TeacherClinician: 'TeacherClinician',
}

type ArtistEnumType = typeof ArtistEnum[keyof typeof ArtistEnum]

function getArtists(): ArtistEnumType[] {
  const output: ArtistEnumType[] = []
  output.push(ArtistEnum.PerformerBand)
  // output.push(ArtistEnum.SessionArtistInstrumentals)
  // output.push(ArtistEnum.RecordProducerStudioEngineer)
  // output.push(ArtistEnum.TeacherClinician)
  return output
}


function getArtistEnumOptions() {
  return convertEnumToOptionsWithCleanLabel(ArtistEnum, printArtist)
}

function getLimitedArtists(): ArtistEnumType[] {
  return getArtists()
}


function getArtistCompletedOptions(): Option[] {
  const output: Option[] = []
  output.push({value: ArtistEnum.PerformerBand, label: printArtist(ArtistEnum.PerformerBand)})
  // output.push({
  //   id: ArtistEnum.SessionArtistInstrumentals,
  //   label: printArtist(ArtistEnum.SessionArtistInstrumentals),
  // })
  // output.push({
  //   id: ArtistEnum.RecordProducerStudioEngineer,
  //   label: printArtist(ArtistEnum.RecordProducerStudioEngineer),
  // })
  // output.push({id: ArtistEnum.TeacherClinician, label: printArtist(ArtistEnum.TeacherClinician)})
  return output
}

function getArtistOptions(): {id: ArtistEnumType; label: string}[] {
  const output: {id: ArtistEnumType; label: string}[] = []
  output.push({id: ArtistEnum.PerformerBand, label: printArtist(ArtistEnum.PerformerBand)})
  // output.push({
  //   id: ArtistEnum.SessionArtistInstrumentals,
  //   label: printArtist(ArtistEnum.SessionArtistInstrumentals),
  // })
  // output.push({
  //   id: ArtistEnum.RecordProducerStudioEngineer,
  //   label: printArtist(ArtistEnum.RecordProducerStudioEngineer),
  // })
  // output.push({id: ArtistEnum.TeacherClinician, label: printArtist(ArtistEnum.TeacherClinician)})
  return output
}

const artistTypes = Object.keys(ArtistEnum) as ArtistEnumType[]

function printArtist(value: ArtistEnumType): string {
  switch (value) {
    case ArtistEnum.PerformerBand:
      return 'Performers / Bands'
    case ArtistEnum.SessionArtistInstrumentals:
      return 'Session Artists / Instrumentals'
    case ArtistEnum.RecordProducerStudioEngineer:
      return 'Record Producers / Studio Engineers'
    case ArtistEnum.TeacherClinician:
      return 'Teachers / Coaches / Clinicians'
    default:
      return ''
  }
}

export  {getArtists, getLimitedArtists, printArtist, ArtistEnum, getArtistCompletedOptions,getArtistOptions, getArtistEnumOptions}
 