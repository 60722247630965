import {lazy, FC, Suspense} from 'react'
import {Routes, Route, Navigate} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue, WithChildren} from '../../infrastructure/deps/metronic/v2'
import {MasterLayout} from '../components/MasterLayout'
import Onboarding from '../pages/onboarding/Onboarding'
import {ProtectedRoute} from './ProtectedRoute'
import { UserRole } from '../../infrastructure/enums/UserRole'

const PrivateRoutes = () => {
  const Dashboard = lazy(() => import('../pages/dashboard/Index'))
  const Connect = lazy(() => import('../pages/connect/Index'))
  const Manage = lazy(() => import('../pages/management/Index'))
  const NotificationsPage = lazy(() => import('../pages/notifications/NotificationsPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        <Route
          path='manage/*'
          element={
            <SuspensedView>
              <ProtectedRoute
                path='manage/*'
                element={<Manage />}
                requiredRoles={[UserRole.PlatformEditor]}
              />
            </SuspensedView>
          }
        />
        <Route
          path='dashboard'
          element={
            <SuspensedView>
              <ProtectedRoute
                path='dashboard'
                element={<Dashboard />}
                requiredRoles={[UserRole.Basic, UserRole.Provider, UserRole.PlatformEditor]}
              />
            </SuspensedView>
          }
        />

       <Route
          path='notifications'
          element={
            <SuspensedView>
              <ProtectedRoute
                path='notifications'
                element={<NotificationsPage />}
                requiredRoles={[UserRole.Basic, UserRole.Provider, UserRole.PlatformEditor]}
              />
            </SuspensedView>
          }
        />
        <Route
          path='onboarding'
          element={
            <SuspensedView>
              <ProtectedRoute
                path='onboarding'
                element={<Onboarding />}
                requiredRoles={[UserRole.Basic, UserRole.Provider, UserRole.PlatformEditor]}
              />
            </SuspensedView>
          }
        />
        <Route
          path='connect/*'
          element={
            <SuspensedView>
              <ProtectedRoute
                path='connect/*'
                element={<Connect />}
                requiredRoles={[UserRole.Basic, UserRole.Provider, UserRole.PlatformEditor]}
              />
            </SuspensedView>
          }
        />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes, SuspensedView}
