/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../infrastructure/deps/metronic/v2'

interface NotificationGroupProps {
  title: string
  description: string
  timestamp: string
  items: React.ReactNode
}

const NotificationGroup: FC<NotificationGroupProps> = ({title, description, items, timestamp}) => {
  return (
    <div className='timeline-item'>
      <div className='timeline-line w-40px'></div>

      <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
        <div className='symbol-label bg-light'>M</div>
      </div>

      <div className='timeline-content mb-10 mt-n1'>
        <div className='pe-3 mb-5'>
          <div className='fs-5 fw-bold mb-2'>
            {description}
          </div>

          <div className='d-flex align-items-center mt-1 fs-6'>
            <div className='text-white me-2 fs-7'>{timestamp}</div>

            <div
              className='symbol symbol-circle symbol-25px'
              data-bs-toggle='tooltip'
              data-bs-boundary='window'
              data-bs-placement='top'
              title='Nina Nilson'
            >
              <img src={toAbsoluteUrl('/media/avatars/300-14.jpg')} alt='img' />
            </div>
          </div>
        </div>
        <div className='overflow-auto pb-5'>{items}</div>
      </div>
    </div>
  )
}

export {NotificationGroup}
