import {Role} from '../../infrastructure/enums/Role'
import {UserRole} from '../../infrastructure/enums/UserRole'
import {DynamicSideBarItem} from './DynamicSideBarItem'

export const DropDownMenuOptions: DynamicSideBarItem[] = [
  {
    name: 'Home',
    route: '/dashboard',
    permission: [UserRole.Basic, UserRole.Provider, UserRole.PlatformEditor],
  },
  {
    name: 'Admin',
    route: '#',
    permission: [UserRole.PlatformEditor],
    subRoutes: [
      {
        name: 'Manage Artists',
        route: '/manage/artists',
      },
      {
        name: 'Manage Venues',
        route: '/manage/venues',
      },
      {
        name: 'Manage Agents',
        route: '/manage/agents',
      },
      {
        name: 'Manage Users',
        route: '/manage/users',
      },
      {
        name: 'Connects',
        route: '/manage/connects',
      },
      {
        name: 'Invites',
        route: '/manage/invitations',
      },
      {
        name: 'Verifications',
        route: '/manage/verifications',
      },
    ],
  },

  {
    name: 'Connect',
    route: '#',
    permission: [UserRole.Basic, UserRole.Provider, UserRole.PlatformEditor],
    subRoutes: [
      {
        name: 'New Connect',
        route: '/dashboard',
        permission: [UserRole.Basic, UserRole.Provider, UserRole.PlatformEditor],
      },
      {
        name: 'Incoming Requests',
        route: '/connect/incoming-requests',
        permission: [UserRole.Basic, UserRole.Provider, UserRole.PlatformEditor],
      },
      {
        name: 'Outreach',
        route: '/connect/outreach',
        permission: [UserRole.Basic, UserRole.Provider, UserRole.PlatformEditor],
      },
    ],
  },
  {
    name: 'Account Settings',
    route: '/manage/accounts',
    permission: [UserRole.Basic, UserRole.Provider, UserRole.PlatformEditor],
  }, 
]
