import React, {FC} from 'react'
import {AiFillAlert, AiFillExclamationCircle} from 'react-icons/ai'
import {Link} from 'react-router-dom'
import {NotificationGroup} from './activity/NotificationGroup'
import {NotificationItem} from './activity/NotificationItem'
import {BsXCircle} from 'react-icons/bs'
import { NotificationGroupItem } from './NotificationGroupItem'

const NotificationDrawer: FC = () => (
  <div
    id='kt_activities'
    className='bg-body'
    data-kt-drawer='true'
    data-kt-drawer-name='activities'
    data-kt-drawer-activate='true'
    data-kt-drawer-overlay='true'
    data-kt-drawer-width="{default:'300px', 'lg': '900px'}"
    data-kt-drawer-direction='end'
    data-kt-drawer-toggle='#kt_activities_toggle'
    data-kt-drawer-close='#kt_activities_close'
  >
    <div className='card shadow-none rounded-0'>
      <div className='card-header' id='kt_activities_header'>
        <h3 className='card-title fw-bolder text-dark'>Here's What You Missed</h3>

        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-active-light-primary me-n5'
            id='kt_activities_close'
          >
            {/* <AiFillExclamationCircle className='fs-1' /> */}
            <BsXCircle className='fs-1' />
          </button>
        </div>
      </div>
      <div className='card-body position-relative' id='kt_activities_body'>
        <div
          id='kt_activities_scroll'
          className='position-relative scroll-y me-n5 pe-5'
          data-kt-scroll='true'
          data-kt-scroll-height='auto'
          data-kt-scroll-wrappers='#kt_activities_body'
          data-kt-scroll-dependencies='#kt_activities_header, #kt_activities_footer'
          data-kt-scroll-offset='5px'
        >
          <div className='timeline'>
            <NotificationGroup
              title={'sdsdsd'}
              description={'There are 2 new tasks for you in “AirPlus Mobile App” project'}
              timestamp={'Added at 4:23 PM by'}
              items={
                <>
                  <NotificationGroupItem
                    title={'Sample notification log #1'}
                    description={'Failed'}
                    badgeText={'Failed'}
                    badgeColor={'danger'}
                    statusText={''}
                    statusColor={'danger'}
                    avatarSrcs={[]}
                  />
                  <NotificationGroupItem
                    title={'Sample notification log #1'}
                    description={'Completed'}
                    badgeText={'Success'}
                    badgeColor={'primary'}
                    statusText={''}
                    statusColor={'primary'}
                    avatarSrcs={[]}
                  />
                </>
              }
            />
          </div>
        </div>
      </div>
      <div className='card-footer py-5 text-center' id='kt_activities_footer'>
        <Link to='/notifications' className='btn btn-bg-body text-primary'>
          View All Notifications
        </Link>
      </div>
    </div>
  </div>
)

export {NotificationDrawer}
