import {useEffect} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {
  reInitMenu,
  PageDataProvider,
  DrawerMessenger,
  ActivityDrawer,
  ThemeModeProvider,
  Content,
  ScrollTop,
} from '../../../infrastructure/deps/metronic/v2'
import {Sidebar} from '../Sidebar'
import {HeaderWrapper} from '../Header'
import {ToolbarWrapper} from '../Toolbar'
import { FooterWrapper } from '../../pages/shared/section/footer'
import { NotificationDrawer } from '../NotificationDrawer/NotificationDrawer'

const MasterLayout = () => {
  const location = useLocation()
  useEffect(() => {
    reInitMenu()
  }, [location.key])

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
          <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
            <HeaderWrapper />
            <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
              {/* <Sidebar/> */}
              <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                <div className='d-flex flex-column flex-column-fluid'>
                  {/* <div className='d-flex justify-content-center'> */}
                    <ToolbarWrapper />
                    <Content>
                      <Outlet />
                    </Content>
                  {/* </div> */}
                  {/* //Create new toolbar wrapper with actions */}
                </div>
                <FooterWrapper />
              </div>
            </div>
          </div>
        </div>

        {/* begin:: Drawers */}
        <NotificationDrawer />
        <DrawerMessenger />
        {/* end:: Drawers */}
        <ScrollTop />
      </ThemeModeProvider>
    </PageDataProvider>
  )
}

export {MasterLayout}
