import {FC} from 'react'
import {toAbsoluteUrl} from '../../../infrastructure/deps/metronic/v2'

interface NotificationItemProps {
  title: string
  description: string
  badgeText: string
  badgeColor: 'primary' | 'success' | 'muted' | 'danger'
  statusText: string
  statusColor: 'primary' | 'success' | 'danger'
  avatarSrcs: string[]
}

const NotificationGroupItem: FC<NotificationItemProps> = ({
  title,
  description,
  badgeText,
  badgeColor,
  statusText,
  statusColor,
  avatarSrcs,
}) => {
  const getBadgeClass = () => {
    switch (badgeColor) {
      case 'primary':
        return 'badge badge-light-primary'
      case 'success':
        return 'badge badge-light-success'
      case 'danger':
        return 'badge badge-light-danger'
      case 'muted':
        return 'badge badge-light text-muted'
      default:
        return 'badge badge-light text-muted'
    }
  }

  const getStatusClass = () => {
    switch (statusColor) {
      case 'primary':
        return 'badge badge-light-primary'
      case 'success':
        return 'badge badge-light-success'
      case 'danger':
        return 'badge badge-light-danger'
      default:
        return 'badge badge-light'
    }
  }

  return (
    <div className='d-flex align-items-center border border-dashed border-gray-300 rounded min-w-750px px-7 py-3 mb-5'>
      <a href='#' className='fs-5 text-dark text-hover-primary fw-bold w-375px min-w-200px'>
        {title}
      </a>

      <div className='min-w-175px pe-2'>
        <span className={getBadgeClass()}>{description}</span>
      </div>

      <div className='symbol-group symbol-hover flex-nowrap flex-grow-1 min-w-100px pe-2'>
        {avatarSrcs.map((src, index) => (
          <div className='symbol symbol-circle symbol-25px' key={index}>
            <img src={toAbsoluteUrl(src)} alt='img' />
          </div>
        ))}
      </div>

      <div className='min-w-125px pe-2'>
        <span className={getStatusClass()}>{statusText}</span>
      </div>

      <a href='#' className='btn btn-sm btn-light btn-active-light-primary'>
        View
      </a>
    </div>
  )
}

export {NotificationGroupItem}
