import {FC} from 'react'
import {Route, Navigate} from 'react-router-dom'
import {useAuth} from '../../infrastructure/local/auth'

import {UserRole} from '../../infrastructure/enums/UserRole'

interface ProtectedRouteProps {
  path: string
  element: JSX.Element
  requiredRoles: UserRole[]
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({path, element, requiredRoles}) => {
  const {currentUser} = useAuth()
  const hasPermission =
    currentUser && requiredRoles.some((role) => currentUser.roles.includes(role))

  return hasPermission ? element : <Navigate to='/error/404' />
}

export {ProtectedRoute}
